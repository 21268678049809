import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  { path: '/',             name: 'home',      component: () => import('../views/Home.vue') },
  { path: '/stories/:id',  name: 'stories',   component: () => import('../views/Stories.vue') }, 
  { path: '/map',          name: 'map',       component: () => import('../views/Map.vue') },
  { path: '/about',        name: 'about',     component: () => import('../views/About.vue') },
  { path: '/m',            name: 'media',     component: () => import('../views/Media.vue')}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
export default router