import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'firebase/firestore';
import firebase from 'firebase/compat/app';

const app = createApp(App).use(store).use(router)
app.config.compilerOptions.isCustomElement = (tag) => {
  return tag.startsWith('a-')
}
app.config.compilerOptions.isCustomElement = (tag) => {
  return tag.startsWith('ion-')
}

app.mount('#app')

const firebaseConfig = {
  apiKey: "AIzaSyCLQKc2RMNrYBLJKMrUmSpyunIkAQY-7cw",
  authDomain: "duck-reach.firebaseapp.com",
  projectId: "duck-reach",
  storageBucket: "duck-reach.appspot.com",
  messagingSenderId: "23370153108",
  appId: "1:23370153108:web:3a0616e68a924760064881",
  measurementId: "G-7SPYMFQSZ6"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);