<template>
  <div v-if="notHome">
    <div class="navbar-menu">
      <div class="polygon"></div>
      <img class="navbar-logo" @click="routeTo('Home', '/')" src="../assets/images/electricity_logo.png">
      <div class="navbar-button" @click="doShowMenu">
        <h2>MENU</h2>
        <div class="icon-menu"></div>
      </div>
    </div>
    <div class="line"></div>
    
    <Expand>
      <div v-if="showMenu" class="menu">
        <Menu :hideMenu="hideMenu"></Menu>
      </div>
    </Expand>
  
  </div>
</template>

<script>
import Expand from "@/components/Expand.vue";
import Menu from "@/components/Menu.vue";
import IconBox from "@/components/IconBox.vue";
export default {
  name: "Navbar",
  components: {
    Expand,
    Menu,
  },
  data() {
    return {
      showMenu: true,
      firstRun:true,
      pageName: "",
    };
  },
  mounted() {
    this.pageName = this.$route.name;
  },
  computed: {
    path(){
      return this.$route.path
    },
    page() {
      return this.$route.name;
    },
    notHome() {
      return this.$route.path != "/";
    },
    
    searchVars() {
      return this.$store.getters.getSearchVars;
    },
    mediaItem(){
      let id=this.searchVars.split("&id=")[1]
      return this.$store.getters.getContentFromID(id)
    },
    mediaItemTitle(){
     if(this.mediaItem){
      return this.mediaItem.title
     }
     return null
    },
    media() {
      return this.$store.getters.getMedia
    },
    
    notMedia() {
      return this.$route.path.indexOf("/stories") >= 0 && !this.media;
      // return this.$route.path != "/m";
    }
  },
  methods: {
    routeTo(name, path) {
      this.hideMenu();
      this.$router.push(path);
    },
    doShowMenu(){
      if(this.showMenu){
        //this.showMenu = false
      }else{
        //this.showMenu=true
        this.$router.push("/stories")
      }
    },
    hideMenu() {
      this.showMenu = false
    },
    trackCode(url){
     let mediaName = 'STORIES_'+url
     console.log(mediaName)
     fathom.trackEvent(mediaName);
   }
    
  },
  watch: {
    mediaItemTitle(val){
      console.log("------ NAV MEDIA TITLE",val)
      if(val){
        this.trackCode(val)
      }
    },
    
    searchVars(val){

      console.log("SEARCH",val,this.mediaItem)

      if(val=="/stories"){
        this.showMenu = true;
      }else{
       // this.trackCode(val)
        this.showMenu = false;
      }
    },
    
    
  }
};
</script>

<style lang="scss" scoped>
.navbar-menu {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.navbar-logo {
  width: 170px;
  height: 100%;
  padding: 0.5em;
  margin-left: 0.5em;
  cursor: pointer;
}
.navbar-button {
  display: flex;
  align-items: center;
  margin: 0.25em 1em 0 0;
  color: var(--charcoal);
  cursor: pointer;
  transition: 0.25s;
}
.navbar-button:hover {
  color: var(--white);
  filter: drop-shadow(0 0 0.5em var(--charcoal));
}
.icon-menu {
  font-size: 1.3em;
  padding: 0 0 0.125em 0.25em;
}
.media-container {
  width: 100%;
  min-height: 100vh;
  background: var(--charcoal) url(@/assets/images/background.jpg);
  background-size: 100%;
}
.line {
  width: 100%;
  height: 2px;
  background-color: var(--orange);
}
.menu {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: var(--charcoal);
  border-bottom: 2px solid var(--orange);
  max-width: var(--app-width);
  z-index: 1000
}
.polygon {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--orange);
  clip-path: polygon(180px 100%, 210px 0%, 100% 0%, 100% 100%);
  z-index: -1;
}
</style>