<template>
  <div class="overlay">
    
    <div class="container" @click="routeTo('Stories', '/stories/a-brilliant-idea')" style="height: 100%;">
      <div class="overlay-button">
        <h1>A BRILLIANT IDEA</h1>
        <h2>Planning and Inspiration</h2>
      </div>
      <div class="background menu1"></div>
    </div>

    <div class="container" @click="routeTo('Stories', '/stories/an-engineering-marvel')" style="height: 100%;">
      <div class="overlay-button">
        <h1>AN ENGINEERING MARVEL</h1>
        <h2>Tough Work and Innovation</h2>
      </div>
      <div class="background menu2"></div>
    </div>

    <div class="container" @click="routeTo('Stories', '/stories/lighting-up-the-city')" style="height: 100%;">
      <div class="overlay-button">
        <h1>LIGHTING UP THE CITY</h1>
        <h2>A Truly Modern City</h2>
      </div>
      <div class="background menu3"></div>
    </div>

    <div class="container" @click="routeTo('Stories', '/stories/bright-sparks')" style="height: 100%;">
      <div class="overlay-button">
        <h1>BRIGHT SPARKS</h1>
        <h2>Pioneers and Visionaries</h2>
      </div>
      <div class="background menu4"></div>
    </div>

    <div class="container" @click="routeTo('Stories', '/stories/the-gorge')" style="height: 100%;">
      <div class="overlay-button">
        <h1>THE GORGE</h1>
        <h2>A Unique Environment</h2>
      </div>
      <div class="background menu5"></div>
    </div>

    <div class="container" @click="routeTo('Map', '/map')" style="height: 100%;">
      <div class="overlay-button">
        <h1>MAP</h1>
        <h2>Locate Stories in the Gorge</h2>
      </div>
      <div class="background menu6"></div>
    </div>

    <div class="container" @click="routeTo('About', '/about')" style="height: 100%; border: 0;">
      <div class="overlay-button">
        <h1>ABOUT</h1>
        <h2>Credits and Acknowledgements</h2>
      </div>
      <div class="background menu7"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Menu",
  components: {},
  props: { hideMenu: Function },
  methods: {
    routeTo(name, path) {
      this.hideMenu();
      this.$store.commit("setMedia",null)
      this.$router.push(path);
    }
  }
};
</script>

<style scoped lang="scss">
.overlay {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(var(--app-height) - 4em);
  position: relative;
  .background, .background:before {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .background:before {
    content: "";
    background-image: linear-gradient(90deg, var(--charcoal) 0%, var(--overlay) 40%, var(--overlay) 60%, var(--charcoal) 100%);
    top: 0;
    left: 0;
  }
}
.background {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: filter 0.25s, height 1s;
  z-index: -1;
}
.container {
  display: flex;
  flex-direction: column;
  position: relative;
  border-bottom: 2px solid var(--orange);
}
.overlay-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0 0 0.5em var(--charcoal))
          drop-shadow(0 0 0.5em var(--charcoal));
  height: 100%;
  cursor: pointer;
  margin: 0;
  h1 {
    font-size: clamp(2em, 8vw, 2.5em);
    margin-bottom: -0.125em;
    text-wrap: nowrap;
  }
  h2 {
    font-size: clamp(1em, 4vw, 1.25em);
  }
}
.menu1 { background-image: url(@/assets/images/overlay_menu_1.jpg); }
.menu2 { background-image: url(@/assets/images/overlay_menu_2.jpg); }
.menu3 { background-image: url(@/assets/images/overlay_menu_3.jpg); }
.menu4 { background-image: url(@/assets/images/overlay_menu_4.jpg); }
.menu5 { background-image: url(@/assets/images/overlay_menu_5.jpg); }
.menu6 { background-image: url(@/assets/images/overlay_menu_6.jpg); }
.menu7 { background-image: url(@/assets/images/overlay_menu_7.jpg); }
</style>