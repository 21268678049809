export default {
  data() {
    return {
      posterIcon: "\ue800",
      videoIcon: "\ue801",
      objectIcon: "\ue802",
      galleryIcon: "\ue803", videoARIcon: "\ue803", videoPortraitIcon: "\ue803",
      peopleIcon: "\ue804",
      interactiveIcon: "\ue805",
      homeIcon: "\ue806",
      fullscreenIcon: "\ue807",
      backIcon: "\ue808",
      forwardIcon: "\ue809",
      crossIcon: "\ue80A",
      podcastIcon: "\ue80B",
      droneIcon: "\ue80C",
      viewpointIcon: "\ue80D",
      textIcon: "\ue80E",
      searchIcon: "\ue80F",

      posterIconText: "&#xe800;",
      videoIconText: "&#xe801;",
      objectIconText: "&#xe802;",
      galleryIconText: "&#xe803;", videoARIconText: "&#xe803;", videoPortraitIconText:"&#xe803;",
      peopleIconText: "&#xe804;",
      interactiveIconText: "&#xe805;",
      homeIconText: "&#xe806;",
      fullscreenIconText: "&#xe807;",
      backIconText: "&#xe808;",
      forwardIconText: "&#xe809;",
      crossIconText: "&#xe80A;",
      podcastIconText: "&#xe80B;",
      droneIconText: "&#xe80C;",
      droneVideoIconText: "&#xe80C;",
      viewpointIconText: "&#xe80D;",
      textIconText: "&#xe80E;",
      searchIconText: "&#xe80F;",
    };
  },
  methods: {
    icon(val) {
      let icon = this[val + "Icon"] || " "
      if (icon == " ") {
        console.log("Missing", val, "Icon")
      }
      switch (val) {
        case "home": icon = this.homeIcon
          break;
      }
      return icon
    },
    iconText(val) {
      let icon = " "
      switch (val) {
        case "poster": icon = this.posterIconText
          break;
        case "video": icon = this.videoIconText
          break;
        case "videoAR": icon = this.videoARIconText
          break;
        case "videoPortrait": icon = this.videoPortraitIconText
          break;
        case "object": icon = this.objectIconText
          break;
        case "videoPortrait": icon = this.galleryIconText
          break;
        case "person": icon = this.peopleIconText
          break;
        case "interactive": icon = this.interactiveIconText
          break;
        case "home": icon = this.homeIconText
          break;
        case "fullscreen": icon = this.fullscreenIconText
          break;
        case "back": icon = this.backIconText
          break;
        case "forward": icon = this.forwardIconText
          break;
        case "cross": icon = this.crossIconText
          break;
        case "podcast": icon = this.podcastIconText
          break;
        case "drone": icon = this.droneIconText
          break;
          case "droneVideo": icon = this.droneIconText
          break;
        case "viewpoint": icon = this.viewpointIconText
          break;
        case "text": icon = this.textIconText
          break;
        case "search": icon = this.searchIconText
          break;
      }
      return icon
    }
  }
}