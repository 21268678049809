<template>
  <div class="icon" style="color: var(--orange);'">
    <div v-html="iconText(icons[0])"></div>
  </div>
</template>

<script>
import iconCodes from '@/mixins/iconCodes'
export default {
  name: "IconBox",
  props: {
    icons: { default: [] }
  },
  mixins: [iconCodes]
};
</script>

<style>
.icon {
  display: flex;
  font-family: "roar-explore";
  font-size: 2em;
  padding: 0.5em;
  filter: drop-shadow(0 0 0.25em var(--charcoal))
          drop-shadow(0 0 0.1em var(--charcoal));
  div {
    display: flex;
    align-items: center;
  }
}
</style>