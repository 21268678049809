import { createStore } from 'vuex'
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import contentData from '../assets/data/electricity.json'
import mediaData from '../assets/data/media.json'
import sequenceData from '../assets/data/sequence.json'
import droneData from '../assets/data/drone.json'
export default createStore({
  state: {
    site: "ElectriCity",
    content: null,
    mediaData: null,
    loading: false,
    userLocation: null,
    searchVars: "",
    media: null,
    width: 500,
    height: 1000,
    imagePath: './images/'
  },
  getters: {
    getSite(state) { return state.site },
    getContent(state) { return state.content },
    getContentFromID: (state) => (id) => {
      if (state.content) {
        return state.content[id]
      } else {
        return null
      }
    },
    getMediaData(state) { return state.mediaData },
    getSequenceData(state) { return state.SequenceData },
    getDroneData(state) { return state.DroneData },
    getLoading(state) { return state.loading },
    getUserLocation(state) { return state.userLocation },
    getSearchVars(state) { return state.searchVars },
    getMedia(state) { return state.media },
    getWidth(state) { return state.width },
    getHeight(state) { return state.height },
    getImagePath(state) { return state.imagePath },
  },
  mutations: {
    setSite(state, payload) { state.site = payload },
    setContent(state, payload) { state.content = payload },
    setMediaData(state, payload) { state.mediaData = payload },
    setSequenceData(state, payload) {
      for (var s in payload) {
        let mid = payload[s].id.split("-")[0]
        if (!state.mediaData[mid].sequence) {
          state.mediaData[mid].sequence = []
        }
        state.mediaData[mid].sequence.push(payload[s])
      }
      state.SequenceData = payload
    },
    setDroneData(state, payload) {
      console.log("SET DRONE DATA", payload)
      let droneImages = []
      let elevation
      let poi
      for (var s in payload) {
        let mid = payload[s].id.split("-")[0]
        elevation = parseInt(payload[s].elevation) - 1
        if (!droneImages[elevation]) {
          droneImages[elevation] = { POI: [] }
        }
        poi = payload[s]
        droneImages[elevation].POI.push(poi)
        if (!state.mediaData[mid].droneImages) {
          state.mediaData[mid].droneImages = []
        }
        console.log("droneImages", droneImages)
        state.mediaData[mid].droneImages = droneImages
      }
      state.droneData = payload
    },
    setLoading(state, payload) { state.loading = payload },
    setUserLocation(state, payload) { state.userLocation = payload },
    setSearchVars(state, payload) { state.searchVars = payload },
    setMedia(state, payload) { state.media = payload },
    setSize(state, payload) {
      state.width = payload.width
      state.height = payload.height
    },
  },
  actions: {
    getJSONContent({ commit, getters, actions }) {
      commit('setLoading', true)
      commit('setContent', contentData)
      commit('setMediaData', mediaData)
      commit('setSequenceData', sequenceData)
      commit('setDroneData', droneData)
      commit('setLoading', false)
    },
    getMedia({ commit, getters }, payload) {
      commit('setLoading', true)
      console.log("GET MEDIA", payload, getters.getMediaData)
      if (getters.getMediaData[payload]) {
        commit("setMedia", getters.getMediaData[payload])
      } else {
        console.log("QUERY NO DATA", payload)
        return null
      }
      commit('setLoading', false)
    }
  }
})