<template>
  <div class="base">
    <Navbar></Navbar>
    <router-view />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import iconCodes from '@/mixins/iconCodes'
export default {
  components: {
    Navbar
  },
  mixins: [iconCodes],
  data() {
    return {
      showNav: true,
    };
  },
  mounted() {
    window.addEventListener('resize', this.appHeight)
    this.appHeight()
    console.log("this.query", this.query)
    this.$store.commit("setSearchVars", this.query)
    this.$store.dispatch('getJSONContent')
  },
  computed: {
    query() {
      return window.location.search;
    },
    fullPath() {
      return this.$route.fullPath
    }
  },
  methods: {
    appHeight() {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', window.innerHeight + "px")
    }
  },
  watch: {
    $route(to, from) {},
    fullPath(val) {
      this.$store.commit("setSearchVars", val)
    },
    query(val) {
      this.$store.commit("setSearchVars", val)
    }
  }
}
</script>

<style lang="scss">
@import './assets/fonts/roar-explore.css';
:root {
  --app-height: 100%;
  --app-width: 768px;
  max-width: var(--app-width);
  margin: 0 auto;
}
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
  font-weight: 400;
}
body {
  font-size: 16px;
  --orange: #F26222;
  --half-orange: #FF440044;
  --charcoal: #18160F;
  --overlay: #18160F99;
  --grey: #AA9977;
  --white: #FFFFFF;
  height: var(--app-height);
  max-width: var(--app-width);
  width: 100vw;
  overflow: hidden;
  background: var(--charcoal) url(@/assets/images/background.jpg);
  background-size: 100%;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}
#app, .base {
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-flow: column;
  height: 100%;
  color: var(--white);
}
@font-face {
  font-family: "PTSerif";
  src: url(assets/fonts/PTSerifProNarrow.woff2) format("woff2");
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "roar-explore";
  src: url(assets/fonts/roar-explore.woff2) format("woff2");
  font-weight: normal;
  font-style: normal;
}
h1 {
  font-family: "PTSerif", Roboto, sans-serif;
  font-size: 2.5em;
  text-transform: uppercase;
  transform: scaleX(0.75);
}
h2 {
  font-family: "PTSerif", Roboto, sans-serif;
  font-size: 1.3em;
  text-transform: uppercase;
  line-height: 1em;
}
p {
  font-family: "PTSerif", Roboto, sans-serif;
  font-size: 1em;
}
button {
  border: none;
  border-radius: 0.25em;
  padding: 5px 7px;
  width: 100%;
  margin: 7px 0;
  transition: 0.1s;
}
button:hover {
  background-color: var(--white);
}
</style>